import { Group } from "../models/group.model";
import { responseHero } from "../utils/axiosInterceptor";
import { Crypto } from "../models/crypto.model";
export const getLatestRecordsForTradingHelper = (group: Group | undefined, assetId: number, takeRecordsCount: number): Promise<Crypto[]> => {
    return new Promise<Crypto[]>((resolve, reject) => {
        if(!group) return;

        responseHero.get(`${process.env.REACT_APP_API_URL}/tradinghelper/${group?.group_id}/${assetId}/${takeRecordsCount}/true`).then((response) => {
            resolve(response.data as Crypto[]);
        })
        .catch(error => {
            console.error(error);
            reject(error);
        });
    });
};