import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import { remove, selectUser } from "../store/reducers/userSlice";
import { useSelector, useDispatch } from 'react-redux';
import ContractTypeList from "../components/contract/ContractTypeList";
import GroupsSubMenu from "../components/GroupsSubMenu";
import { checkTokenValidity } from "../utils/checkTokenValidity";
import RateDropLetter from "../pages/admin/RateDropLetter";

export const Layout = () => {
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation()

  const logOut = () => {
    dispatch(remove());

    navigate('/'); // set to home
    navigate(0); // refresh
  }

  // Function to check if a route is active
  const isRouteActive = (pathname: string) => {
    return location.pathname === pathname;
  };

  const isTokenValid = checkTokenValidity();

  const HumanSubMenu = () => (
    <nav className="bg-dark-blue">
        <div className="max-w-screen-xl px-4 py-1 mx-auto">
            <div className="flex items-center">
                <ul className="flex flex-row font-medium mt-0 mr-6 space-x-8 text-md">
                    <li>
                        <Link className={`text-lg hover:text-cream ${isRouteActive('/gold') ? 'text-cream' : 'text-white'}`} to="/gold">Human D.C - Aranyprogram</Link>
                    </li>

                    <li>
                        <Link className={`text-lg hover:text-cream ${isRouteActive('/altcoin') ? 'text-cream' : 'text-white'}`} to="/altcoin">Human D.C - Altcoin</Link>
                    </li>

                    <li>
                        <Link className={`text-lg hover:text-cream ${isRouteActive('/minedtrading') ? 'text-cream' : 'text-white'}`} to="/minedtrading">Human D.C - Bányászott Bitcoin kereskedés</Link>
                    </li>

                    <li>
                        <Link className={`text-lg hover:text-cream ${isRouteActive('/temp') ? 'text-cream' : 'text-white'}`} to="/temp">Human D.C - Ideiglenes csoportállások</Link>
                    </li>
                    
                </ul>
            </div>
        </div>
    </nav>
  )

  const MiningMachineSubMenu = () => (
    <nav className="bg-dark-blue">
        <div className="max-w-screen-xl px-4 py-1 mx-auto">
            <div className="flex items-center">
                <ul className="flex flex-row font-medium mt-0 mr-6 space-x-4 text-md">
                    <li>
                        <Link className={`text-lg hover:text-cream ${isRouteActive('/allmachine') ? 'text-cream' : 'text-white'}`} to="/allmachine">Összes bányagép listája</Link>
                    </li>

                    <li>
                        <Link className={`text-lg hover:text-cream ${isRouteActive('/miningmachinesdaily') ? 'text-cream' : 'text-white'}`} to="/miningmachinesdaily">Bányagéptípusok - Napi értékek</Link>
                    </li>

                    <li>
                        <Link className={`text-lg hover:text-cream ${isRouteActive('/miningbeneficiaries') ? 'text-cream' : 'text-white'}`} to="/miningbeneficiaries">Bányagép - Kedvezményezettek</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  )

  const GroupManagerSubMenu = () => (
    <nav className="bg-dark-blue">
        <div className="max-w-screen-xl px-4 py-1 mx-auto">
            <div className="flex items-center">
                <ul className="flex flex-row font-medium mt-0 mr-6 space-x-4 text-md">
                    <li>
                        <Link className={`text-lg hover:text-cream ${isRouteActive('/group-connections') ? 'text-cream' : 'text-white'}`} to="/group-connections">Kapcsolódások a csoportok között</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  )

  const NewsLetterSubMenu = () => (
    <nav className="bg-dark-blue">
        <div className="max-w-screen-xl px-4 py-1 mx-auto">
            <div className="flex items-center">
                <ul className="flex flex-row font-medium mt-0 mr-6 space-x-4 text-md">
                    <li>
                        <Link className={`text-lg hover:text-cream ${isRouteActive('/breakingdrop') ? 'text-cream' : 'text-white'}`} to="/breakingdrop">Riasztás - Árfolyamesés</Link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  )

  return (
    <>   
    {isTokenValid ? 
    <>
        <nav className="border-brown bg-dark-blue border-b-2">
            <div className="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl p-4">
                <a href="#" className="flex items-center">
                    <img draggable={false} src="./svg/new-logo.png" className="h-16 mr-3" alt="Logo"/>
                    <span className="self-center text-2xl font-semibold whitespace-nowrap text-cream">HUMAN DC. - Admin Panel</span>
                </a>
                <div className="flex items-center">
                    <a href="#" className="mr-6 text-sm text-white hover:underline"> {user.userInfo.name}</a>
                    <p onClick={logOut} className="text-sm  text-cream hover:underline cursor-pointer">Kilépés</p>
                </div>
            </div>
        </nav>
        <nav className="bg-dark-blue">
            <div className="max-w-screen-xl px-4 py-3 mx-auto">
                <div className="flex items-center">
                    <ul className="flex flex-row font-medium mt-0 mr-6 space-x-8 text-sm">
                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/') ? 'text-cream underline' : 'text-white'}`} to="/">Főoldal</Link>
                        </li>

                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/newsletter') ? 'text-cream underline'  : 'text-white'}`} to="/newsletter">Hírlevél</Link>
                        </li>

                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/newsmanager') ? 'text-cream underline'  : 'text-white'}`} to="/newsmanager">Videók</Link>
                        </li>
                        {/*
                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/vote') ? 'text-cream underline'  : 'text-white'}`} to="/vote">Szavazatok</Link>
                        </li>*/}
                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/daisy') ? 'text-cream underline'  : 'text-white'}`} to="/daisy">D.A.I.SY</Link>
                        </li>

                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/hpm') ? 'text-cream underline'  : 'text-white'}`} to="/hpm">Human D.C</Link>
                        </li>

                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/miningmachines') ? 'text-cream underline'  : 'text-white'}`} to="/miningmachines">Bányagépek</Link>
                        </li>
                        
                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/depositregister') ? 'text-cream underline'  : 'text-white'}`} to="/depositregister">Befizetések</Link>
                        </li>

                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/group-manager') ? 'text-cream underline'  : 'text-white'}`} to="/group-manager">Csoportkezelő</Link>
                        </li>

                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/toplist') ? 'text-cream underline'  : 'text-white'}`} to="/toplist">Toplista</Link>
                        </li>

                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/contracts') ? 'text-cream underline'  : 'text-white'}`} to="/contracts">Szerződéskezelő</Link>
                        </li>
                        
                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/manager') ? 'text-cream underline'  : 'text-white'}`} to="/manager">Ügyfelek</Link>
                        </li>
                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/orgmanager') ? 'text-cream underline'  : 'text-white'}`} to="/orgmanager">Cégek</Link>
                        </li>
                        <li>
                            <Link className={`hover:text-cream ${isRouteActive('/company') ? 'text-cream underline'  : 'text-white'}`} to="/company">Cégadatok</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        </>
        : null }

        {location.pathname === '/contracts' && (
                <ContractTypeList/>
        )}

        {(location.pathname === '/hpm' || location.pathname === '/gold' || location.pathname === '/altcoin' || location.pathname === '/temp' || location.pathname === '/minedtrading') && (
            <>
                <HumanSubMenu/>
            </>
        )}
        
        {location.pathname === '/altcoin' && (
            <GroupsSubMenu system_id={4}/>
        )}

        {(location.pathname === '/daisy') && (
            <>
                <GroupsSubMenu system_id={1}/>
            </>
        )}

        {location.pathname === '/hpm' && (
            <GroupsSubMenu system_id={2}/>
        )}

        

        {(location.pathname === '/group-manager' || location.pathname === '/group-connections') && (
            <>
                <GroupManagerSubMenu/>
            </>
        )}

        {(location.pathname === '/miningmachines' || location.pathname === '/miningmachinesdaily' || location.pathname === '/miningbeneficiaries' || location.pathname === '/allmachine') && (
            <>
                <MiningMachineSubMenu/>
            </>
        )}

        {(location.pathname === '/miningmachines') && (
            <>
                <GroupsSubMenu system_id={3}/>
            </>
        )}

        {(location.pathname === '/newsletter' || location.pathname === '/breakingdrop') && (
            <>
                <NewsLetterSubMenu/>
            </>
        )}
        <Outlet />
    </>
  )
};
export default Layout;