import React, { useEffect } from "react";
import { selectMenuStates } from "../../../../../store/reducers/menuSlice";
import { useSelector } from "react-redux";
import { getMyCryptos } from "../../../../../api/getMyCryptos";
import { getDepositRecords } from "../../../../../api/getHumanDCFunctions";
import { Group } from "../../../../../models/group.model";
import { DepositMetaData } from "../../../../../types/humandc";


export default function FormContainer () {

    const [portfolioStates, setPortfolioStates] = React.useState<DepositMetaData[]>([]); // portfolio states
    const menu = useSelector(selectMenuStates)
    const [loading, setLoading] = React.useState<boolean>(false);
    
    const getPortfolio = React.useCallback(async (group: Group) => {
        setLoading(true)
        try {
            const deposits = await getDepositRecords(group);
            if (deposits.length > 0) {
                const portfolio = await getMyCryptos(group.group_id, deposits.map(deposit => deposit.id));
                if (portfolio) {
                    setPortfolioStates(portfolio.depositMetaData);
                }
            }
        }
        finally {
            setLoading(false)
        }
    }, []);
    

    useEffect(() => {
        if(!menu?.daisyMenu) return;
        getPortfolio(menu?.daisyMenu);
    }, [menu?.daisyMenu, getPortfolio])

    // styling
    const dataRowStyling = "text-center font-bold p-3 border-2 hover:opacity-80 cursor-pointer"

    return (
        <>
        {loading ? <div className="m-5">Kérlek várj...</div> : (
        <div className="w-full overflow-x-auto text-white h-full">
            <div style={{ maxHeight: 600, overflowY: 'auto' }}>
            <table className="w-full shadow-lg text-sm border-white border-[10px]">
                    <thead className="bg-gray-800 sticky top-0">
                        <tr>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Befizetés dátuma</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Név</th>
                            <th className="border p-2 hover:opacity-80 cursor-pointer">Százalékos megtérülés</th>
                        </tr>
                    </thead>
                    <tbody className="bg-dark-blue text-white">
                        {portfolioStates.map((entity, index) => (
                            <tr key={entity.id} className="border even:bg-dark-blue odd:bg-light-blue hover:bg-gray-700">
                                <td className={dataRowStyling}>{entity.date.toString().split('T')[0]}</td>
                                <td className={dataRowStyling}>{entity.name}</td>
                                <td className={`${dataRowStyling} ${entity.rate < 0 ? 'text-error' : 'text-success'}`}>{entity.rate}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>

            </div>
        </div>
        )}
        </>
    )
}