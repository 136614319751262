import React from "react"
import { User } from "../../models/user.model";
import RemoveUserModal from "./modals/RemoveUserModal";
import ModifyUserModal from "./modals/ModifyUserModal";
import { responseHero } from "../../utils/axiosInterceptor";
import { Spinner } from "../../utils/Spinner";
import { useLocation, useNavigate } from "react-router";
import { Group } from "../../models/group.model";
import { toast } from "react-toastify";
import { dateFormatter } from "../../utils/dateFormatter";
import { Template } from "../../models/templates.model";
import { SET_SELECTED_USER } from "../../store/reducers/selectedUserSlice";
import { useDispatch } from "react-redux";
import CreateUserModal from "./modals/CreateUserModal";
import { Organization } from "../../models/organization.model";
import { REMOVE_SELECTED_ORG } from "../../store/reducers/selectedOrgSlice";
import GoToUserProfile from "./modals/GoToUserProfile";
import { UserData } from "../../store/reducers/userSlice";

type UserListProps = {
    searchText: string; // searchText prop
    searchOption: string;
    selectedProject: number;
};

export default function UserList ({ searchText, searchOption, selectedProject } : UserListProps) {

    const [originalUsers, setOriginalUsers] = React.useState<User[]>([]);
    const [users, setUsers] = React.useState<User[]>([])
    const [spinner, setSpinner] = React.useState<boolean>(true)
    const [stateChange, setStateChange] = React.useState<boolean>(true)

    const dispatch = useDispatch()
    const navigate = useNavigate();

    const { state } = useLocation();

    let group: Group | undefined = undefined;
    let template: Template | undefined = undefined;
    let org: Organization | undefined = undefined;

    if (state && state.group) {
        group = state.group;
    }

    if (state && state.template) {
        template = state.template;
    }

    if (state && state.org) {
        org = state.org;
    }

    const addMemberToGroup = async (user: User) => {
        if(!group) {
            return;
        }

        await responseHero.post(`${process.env.REACT_APP_API_URL}/addUserToGroup`, { group_id: group.group_id, user_id: user.user_id }).then((response) => {
            toast.success(`${user.name} sikeresen hozzá lett adva a következőhöz: ${group?.group_name}`)
        }).catch(error => {
            console.error(error.response.data);

            toast.error('Elképzelhető, hogy a felhasználó már a csoport tagja!')
        })
    }

    const addMemberToContract = async (user: User) => {
        if(!user) {
            return;
        }
        
        dispatch(SET_SELECTED_USER(user))
        dispatch(REMOVE_SELECTED_ORG())
        navigate('/contracts');
    } 

    const addMemberToOrganization = async (user: User) => {
        if(!org) {
            return;
        }

        await responseHero.put(`${process.env.REACT_APP_API_URL}/leader/${org.id}`, { user_id: user.user_id }).then((response) => {
            toast.success(`${user.name} sikeresen hozzá lett adva a következőhöz: ${org?.full_name}`)

            navigate('/orgmanager')
        }).catch(error => {
            console.error(error.response.data);

            toast.error('Hiba a hozzárendelés közben!')
        })
    }

    const filterUsers = (users: User[], searchOption: string, searchText: string) => {
        const searchValue = searchText.toLowerCase();
        return users.filter((user) => {
          if (searchOption === 'name') {
            return user.name.toLowerCase().includes(searchValue);
          } else if (searchOption === 'email') {
            return user.email.toLowerCase().includes(searchValue);
          } else if (searchOption === 'phone') {
            return user.phone.includes(searchValue);
          }
          return true;
        });
      };
    
      const getUsers = async () => {
        setSpinner(true);
        try {
          const response = await responseHero.get(`${process.env.REACT_APP_API_URL}/users/`);
          setUsers(response.data);
          setOriginalUsers(response.data);  // Save the original list
        } catch (error) {
          console.error(error);
        }
        setSpinner(false);
      };
    
      React.useEffect(() => {
        getUsers();
      }, [selectedProject, stateChange]);
    
      React.useEffect(() => {
        const filteredUsers = filterUsers(originalUsers, searchOption, searchText);
        setUsers(filteredUsers);
      }, [searchOption, searchText, originalUsers]);

    /* Modals */
    const [showRemoveModal, setShowRemoveModal] = React.useState<boolean>(false); // remove modal state
    const [showModifyModal, setShowModifyModal] = React.useState<boolean>(false); // modify modal state
    const [showCreateModal, setShowCreateModal] = React.useState<boolean>(false); // create user modal state
    const [showStalkerModal, setShowStalkerModal] = React.useState<boolean>(false); // go to stalker modal
    const [selectedUser, setSelectedUser] = React.useState<User>();

    const openModal = (user: User, modalType: string) => {
        setSelectedUser(user);
        switch(modalType) {
            case 'modify': 
                return setShowModifyModal(true);
            case 'remove':
                return setShowRemoveModal(true);
            default:
                return;
        }
    };

    const openCreateModal = () => {
        setShowCreateModal(true);
    }

    const openStalkerModal = (user: User) => {
        setSelectedUser(user)
        setShowStalkerModal(true)
    }

    const closeModal = () => {
        setShowRemoveModal(false);
        setShowModifyModal(false);
        setShowCreateModal(false);
        setShowStalkerModal(false);
        setSelectedUser(undefined);
    };

    const handleStateChange = () => {
        setStateChange(!stateChange)
    }

    return (
        <>
        <RemoveUserModal title="Felhasználó törlése" user={selectedUser} showModal={showRemoveModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <ModifyUserModal title="adatai" user={selectedUser} showModal={showModifyModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <CreateUserModal title="Új ügyfél" user={selectedUser} showModal={showCreateModal} closeModal={closeModal} handleStateChange={handleStateChange}/>
        <GoToUserProfile title="Átirányítás" user={selectedUser} showModal={showStalkerModal} closeModal={closeModal}/>
            
            <div className="m-10">
            <Spinner show={spinner} width={12} height={12}/>
            <button onClick={openCreateModal} className={`hover:opacity-80 text-center rounded-lg shadow-lg bg-success text-sm text-white p-2`}>Új ügyfél felvétele</button>
            <p className="text-gray-800 py-2">Talált ügyfelek száma: <span className="text-brown">{users.length} db</span></p>
            
            {(users && !spinner) && ( 
               
                <div className="flex justify-center border p-3 m-2 mx-auto">
                    <table className="min-w-full divide-y divide-gray-200 table-fixed divide-gray-600">
                        <thead className="bg-dark-blue text-cream">
                            <tr>
                                <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">ID</th>
                                <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Név</th>
                                <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Email</th>
                                <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Telefonszám</th>
                                <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Bankszámlaszám</th>
                                <th scope="col" className="py-3 px-6 text-xs font-medium tracking-wider text-left uppercase text-cream">Bejelentkezés</th>
                                
                                
                                {(!group && !template && !org) && (
                                    <>
                                        <th scope="col" className="p-4"><span className="sr-only">Kimutatás</span></th>
                                        <th scope="col" className="p-4"><span className="sr-only">Módosítás</span></th>
                                        <th scope="col" className="p-4"><span className="sr-only">Törlés</span></th>
                                    </>
                                )}

                                {(group || template || org) && (
                                    <>
                                        <th scope="col" className="p-4"><span className="sr-only">Kiválaszt</span></th>
                                    </>
                                )}
                                    
                            </tr>
                        </thead>
                        
                        <tbody className="divide-y bg-dark-blue divide-gray-600">
                            {users.map((user, index) => ( 
                                <tr key={index} className={`hover:bg-light-blue ${selectedUser === user ? `bg-light-blue` : `bg-dark-blue`}`}>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{user.user_id}</td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{user.name} {user.admin ? '[ADMIN]' : null}</td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{user.email}</td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{(user.phone === '0' || user.phone === '') ? 'Nincs telefonszám beállítva' : user.phone.charAt(0) === '+' ? user.phone : '+36' + user.phone}</td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{(user.card_number === '0' || user.card_number === '') ? 'Nincs megadva' : user.card_number}</td>
                                    <td className="py-4 px-6 text-sm font-medium whitespace-nowrap text-white">{user.last_logged.toString() === '1900-01-01T00:00:00.000Z' ? 'N/A' : dateFormatter(user.last_logged.toString())}</td>
                                    
                                    {(!group && !template && !org) && (
                                        <>
                                            <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                                                <button onClick={() => openStalkerModal(user)} className="text-indigo-300 hover:underline">Kimutatás</button>
                                            </td>
                                            <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                                                <button onClick={() => openModal(user, 'modify')} className="text-cream hover:underline">Módosít</button>
                                            </td>
                                            <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                                                <button onClick={() => openModal(user, 'remove')} className="text-cream hover:underline">Törlés</button>
                                            </td>
                                        </>
                                    )}
                                    
                                    {group && (
                                        <>
                                            <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                                                <button onClick={() => addMemberToGroup(user)} className="text-cream hover:underline">Hozzáadás: {group?.group_name}</button>
                                            </td>
                                        </>
                                    )}

                                    {template && (
                                        <>
                                            <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                                                <button onClick={() => addMemberToContract(user)} className="text-cream hover:underline">Hozzárendel: {template?.name}</button>
                                            </td>
                                        </>
                                    )}

                                    {org && (
                                        <>
                                            <td className="py-4 px-6 text-sm font-medium text-right whitespace-nowrap">
                                                <button onClick={() => addMemberToOrganization(user)} className="text-cream hover:underline">Hozzárendel: {org.name}</button>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                            
                    </table>
                    
                </div>
                )}
            </div>
        </>
    )
}
