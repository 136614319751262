import { useSelector } from "react-redux";
import { Asset } from "../../../../models/assets.model";
import React from "react";
import { selectMenuStates } from "../../../../store/reducers/menuSlice";
import { Crypto } from "../../../../models/crypto.model";
import { calculateAvarageExchangeRateOfLatestTransactions } from "./helperfunctions/calcAvgExchangeRate";
import { getPercentage } from "./helperfunctions/getPercent";
import { getCurrentLiquidityValue } from "../../../../api/getCurrentLiquidityValue";
import { TradingHelper } from "../../../../models/tradinghelper.model";

type AssetsMenuProps = {
    selectedAsset: Asset | undefined;
    takenRecords: Crypto[];
    tradingHelperData: TradingHelper | undefined;
}

export default function AssetBuyPanel({ selectedAsset, takenRecords, tradingHelperData } : AssetsMenuProps) {

    return (
    <>
        <div className="">    
            <h1 className="text-2xl font-semibold py-2">{selectedAsset?.name} : <span className="text-success">Vásárlás</span></h1>
            {(takenRecords.length > 0 && tradingHelperData) ? (
                <>
                <div className="bg-white py-2 px-2 rounded shadow-sm shadow-gray-400 mt-5">
                    <h1 className="text-2xl text-center">Rendelkezésre álló likviditás: <span className="text-emerald-600">{tradingHelperData.fixedLiquidity.toLocaleString('en-En').replace(',', ' ')}$</span></h1>
                
                    <div className="flex space-x-4 px-2 py-2 items-center">
                        <ul className="px-2 py-2 bg-gray-100 rounded w-full flex flex-row space-xs-4 items-center">
                            <li className="rounded-lg py-2 ml-2 text-white bg-dark-blue px-2">
                                <p>I. vásárlási szint: A likviditás: <span className="text-cream">{tradingHelperData.liquidity_lvl1}%</span> -a.</p>
                                <p className="text-center text-xl text-emerald-200">
                                    {getPercentage(tradingHelperData.fixedLiquidity, tradingHelperData.liquidity_lvl1).toLocaleString('en-En').replace(',', ' ')}$
                                </p>
                            </li>
                            <li className="rounded-lg py-2 ml-2 text-white bg-dark-blue px-2">
                                <p>II. vásárlási szint: A likviditás: <span className="text-cream">{tradingHelperData.liquidity_lvl2}%</span> -a.</p>
                                <p className="text-center text-xl text-emerald-200">
                                    {getPercentage(tradingHelperData.fixedLiquidity, tradingHelperData.liquidity_lvl2).toLocaleString('en-En').replace(',', ' ')}$
                                </p>
                            </li>
                            <li className="rounded-lg py-2 ml-2 text-white bg-dark-blue px-2">
                                <p>III. vásárlási szint: A likviditás: <span className="text-cream">{tradingHelperData.liquidity_lvl3}%</span> -a.</p>
                                <p className="text-center text-xl text-emerald-200">
                                    {getPercentage(tradingHelperData.fixedLiquidity,tradingHelperData.liquidity_lvl3).toLocaleString('en-En').replace(',', ' ')}$
                                </p>
                            </li>
                        </ul>
                    </div>

                    <div className="flex space-x-4 px-2 py-2 justfiy-center items-center mt-5">
                        <p className="text-xl"><span className="text-brown">{tradingHelperData.decreasePercent}%-os</span> árfolyam-csökkenésnél az árfolyam: <span className="text-success font-semibold ">{(tradingHelperData.avg_transaction_rate * (1 - (tradingHelperData.decreasePercent / 100))).toFixed(2)}$</span></p>
                    </div>

                </div>
                </>
            ) : (
                <div className="bg-white py-2 px-2 rounded shadow-sm shadow-gray-400 mt-5">
                    <p className="text-xl">Jelenleg még nincsen konfigurálva a következő: <span className="font-semibold">{selectedAsset?.name}</span></p>
                </div>
            )}
        </div>
      </>
    );
  }
