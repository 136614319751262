import React, { useEffect, useState } from "react";
import { responseHero } from "../../../../../utils/axiosInterceptor";
import { toast } from "react-toastify";
import { Crypto } from "../../../../../models/crypto.model";
import { TradingHelper } from "../../../../../models/tradinghelper.model";
import { Group } from "../../../../../models/group.model";
import { Asset } from "../../../../../models/assets.model";
import { updateTradingHelperData } from "../../../../../api/updateTradingHelperData";
import { getLatestRecordsForTradingHelper } from "../../../../../api/getLatestRecordsForTradingHelper";
import { calculateAvarageExchangeRateOfLatestTransactions } from "../helperfunctions/calcAvgExchangeRate";
import { group } from "console";
import { getCurrentLiquidityValue } from "../../../../../api/getCurrentLiquidityValue";
import { getCryptoRecords } from "../../../../../api/getHumanDCFunctions";

type ModifiedPayload = Omit<TradingHelper, 
    'group_id' | 'group' | 'assetId' | 'asset' | 'avg_transaction_rate' | 'currentPrice' | 'change'
>;

type ModalProps = {
    title: string;
    group: Group | undefined;
    showModal: boolean;
    asset: Asset;
    closeModal: () => void;
    row: TradingHelper | undefined;
    handleStateChange: () => void;
}

type AutoFixedValues = {
    quantity: string;
    liquidity: number;
}
export const ModifyTradingHelperDataModal = ({ group, row, title, showModal, closeModal, asset, handleStateChange } : ModalProps) => {

        /*
            Params:

            liquidity_lvl1: number;
            liquidity_lvl2: number;
            liquidity_lvl3: number;

            coin_lvl1: number;
            coin_lvl2: number;
            coin_lvl3: number;

            fixedLiquidity: number;
            fixedCoinQuantity: string;

            openedRecords: number;
            avg_transaction_rate: number;

            currentPrice: number;
            change: number;

        */

        const [autoFixedValues, setAutoFixedValues] = useState<AutoFixedValues>({
            liquidity: 0,
            quantity: ""
        });

        const [recordData, setRecordData] = useState<ModifiedPayload>({
            id: -1,
            liquidity_lvl1: 0,
            liquidity_lvl2: 0,
            liquidity_lvl3: 0,

            coin_lvl1: 0,
            coin_lvl2: 0,
            coin_lvl3: 0,

            fixedLiquidity: 0,
            fixedCoinQuantity: "",

            openedRecords: 0,

            increasePercent: 10,
            decreasePercent: 10,
        });

        
        React.useEffect(() => {
            if(!row || !group) return;

            setRecordData({
                id: row.id,
                liquidity_lvl1: row.liquidity_lvl1,
                liquidity_lvl2: row.liquidity_lvl2,
                liquidity_lvl3: row.liquidity_lvl3,

                coin_lvl1: row.coin_lvl1,
                coin_lvl2: row.coin_lvl2,
                coin_lvl3: row.coin_lvl3,

                fixedLiquidity: row.fixedLiquidity,
                fixedCoinQuantity: row.fixedCoinQuantity,

                increasePercent: row.increasePercent,
                decreasePercent: row.decreasePercent,

                openedRecords: row.openedRecords,

            });

            if(row.fixedLiquidity === 0) {
                setDefVal("liquidity");
            }

            if(row.fixedCoinQuantity === "") {
                setDefVal("quantity");
            }
        }, [row])

        const setDefVal = (key: string) => {
            if(!group) return toast.warn("No groupId found!")
            switch (key) {
                case 'quantity': {

                    getCryptoRecords(group, asset.id)
                    .then((response) => {
                        setRecordData(prevData => ({
                            ...prevData,
                            fixedCoinQuantity: response.quantity.toString()
                        }))
                    })
                    .catch((error) => {
                        console.error(error);
                    });

                    break;
                }
                case 'liquidity': {

                    getCurrentLiquidityValue(group.group_id)
                    .then((response) => {
                        setRecordData(prevData => ({
                            ...prevData,
                            fixedLiquidity: response.amount
                        }))
                    })
                    .catch((error) => {
                        console.error(error);
                    });

                    break;
                }
                default: {
                    console.log(`Sorry, we are out of ${key}.`);
                }
            }
        }


        const handleChange = (type: keyof ModifiedPayload, e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value;

            if(type !== "fixedCoinQuantity") {
                setRecordData(prevData => ({
                    ...prevData,
                    [type]:  parseInt(inputValue, 10)
                }));
            } else if (type === "fixedCoinQuantity" && /^(\d*\.)?\d{0,8}$/.test(inputValue)) {
                setRecordData((prevData) => ({
                ...prevData,
                [type]: inputValue,
            }));
            
        };
    }

        const updateRecord = async () => {
            if(!group || !row) return;
            try {
                const takenRecords = await getLatestRecordsForTradingHelper(group, asset.id, recordData?.openedRecords);
                
                const tradingHelperData = {
                    ...recordData, 
                    avg_transaction_rate: calculateAvarageExchangeRateOfLatestTransactions(takenRecords)
                }

                await updateTradingHelperData(group.group_id, asset.id, tradingHelperData); // save

                closeModal();
                handleStateChange();
                toast.info("Sikeres az új konfigurációs adatok mentése!");
            } catch (error) {
                toast.error("Sikertelen az új konfigurációs adatok mentése!");
            }
            
        }

        return (
            <>
            {showModal && (
                <>
                    <div className="flex justify-center items-center overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                        <div className="relative w-full my-6 mx-auto max-w-4xl">
                            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                                    <h3 className="text-xl text-brown font-semibold"><span className="text-dark-blue">HUMAN D.C: Kereskedési segédlet</span> - {title} | <span className="text-emerald-600">{asset?.name} ({asset?.code})</span></h3>
                                </div>
                                <div className="relative p-6 flex-auto">
                                    <form>
                                        <div className="p-2 rounded shadow-md w-max m-auto">
                                            <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                                <span className="text-success">Megjeleníteni kívánt sorok száma </span> <br/>Érték
                                            </label>
                                            <input
                                                value={recordData?.openedRecords}
                                                onChange={(e) => handleChange('openedRecords', e)}
                                                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="number" type="number" placeholder="Darab"/>
                                        </div>

                                        <h1 className="text-xl text-center mt-5">Vásárlás: </h1>
                                        <div className="flex p-2 rounded shadow-md bg-green-300 mb-5">

                                            <div className="w-1/5 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                                    <span className="text-success">Rögzített </span> <br/>Likviditás
                                                </label>
                                                <input
                                                    value={recordData?.fixedLiquidity}
                                                    onChange={(e) => handleChange('fixedLiquidity', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="number" type="number" placeholder="USD"/>
                                            </div>

                                            <div className="w-1/5 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                                    Árfolyam <span className="text-error">csökkenés</span> mértéke
                                                </label>
                                                <input
                                                    value={recordData?.decreasePercent}
                                                    onChange={(e) => handleChange('decreasePercent', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="number" type="number" placeholder="%-os érték"/>
                                            </div>

                                            <div className="w-1/5 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                                    <span className="text-success">Likviditás </span> <br/>Vásárlás százalék I.
                                                </label>
                                                <input
                                                    value={recordData?.liquidity_lvl1}
                                                    onChange={(e) => handleChange('liquidity_lvl1', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="number" type="number" placeholder="%-os érték"/>
                                            </div>

                                            <div className="w-1/5 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                                    <span className="text-success">Likviditás </span> <br/>Vásárlás százalék II.
                                                </label>
                                                <input
                                                    value={recordData?.liquidity_lvl2}
                                                    onChange={(e) => handleChange('liquidity_lvl2', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="number" type="number" placeholder="%-os érték"/>
                                            </div>

                                            <div className="w-1/5 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                                    <span className="text-success">Likviditás </span> <br/>Vásárlás százalék III.
                                                </label>
                                                <input
                                                    value={recordData?.liquidity_lvl3}
                                                    onChange={(e) => handleChange('liquidity_lvl3', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="number" type="number" placeholder="%-os érték"/>
                                            </div>
                                        </div>

                                        <h1 className="text-xl text-center mt-5">Eladás: </h1>
                                        <div className="flex p-2 rounded shadow-md bg-red-200 mb-5">

                                            <div className="w-1/5 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                                    <span className="text-success">Rögzített </span> <br/>Coin mennyiség
                                                </label>
                                                <input
                                                    value={recordData?.fixedCoinQuantity}
                                                    onChange={(e) => handleChange('fixedCoinQuantity', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="string" type="number" placeholder="Darab"/>
                                            </div>

                                            <div className="w-1/5 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                                    Árfolyam <span className="text-success">emelkedés</span> mértéke
                                                </label>
                                                <input
                                                    value={recordData?.increasePercent}
                                                    onChange={(e) => handleChange('increasePercent', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="number" type="number" placeholder="%-os érték"/>
                                            </div>

                                            <div className="w-1/5 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                                    <span className="text-success">Mennyiség </span> <br/>Eladási százalék I.
                                                </label>
                                                <input
                                                    value={recordData?.coin_lvl1}
                                                    onChange={(e) => handleChange('coin_lvl1', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="number" type="number" placeholder="%-os érték"/>
                                            </div>

                                            <div className="w-1/5 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                                    <span className="text-success">Mennyiség </span> <br/>Eladási százalék II.
                                                </label>
                                                <input
                                                    value={recordData?.coin_lvl2}
                                                    onChange={(e) => handleChange('coin_lvl2', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="number" type="number" placeholder="%-os érték"/>
                                            </div>

                                            <div className="w-1/5 pr-2 text-center items-center">
                                                <label className="block text-sm font-bold mt-5 text-brown text-center justify-center" htmlFor="crypto_1">
                                                    <span className="text-success">Mennyiség </span> <br/>Eladási százalék III.
                                                </label>
                                                <input
                                                    value={recordData?.coin_lvl3}
                                                    onChange={(e) => handleChange('coin_lvl3', e)}
                                                    className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="number" type="number" placeholder="%-os érték"/>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="flex items-center justify-end p-6 border-t border-solid border-blueGray-200 rounded-b">
                                <button
                                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={closeModal}
                                >
                                    Mégse
                                </button>
                                <button
                                    className="text-cream bg-dark-blue font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                                    type="button"
                                    onClick={updateRecord}
                                >
                                    Mentés
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"></div>
                    </>
                )}
            </>
        );
    };
